import { Image } from "@mantine/core";
import React, { useMemo } from "react";
import {
  IStoreProduct,
  IStoreProductRating,
} from "../../../models/product/types";
import { log } from "../../../utils/logger";
import { RatingStars } from "../../libs/Rating/RatingStars";
import { PriceTag } from "./Price/PriceTag";
import { PriceTagBouncing } from "./Price/PriceTagBouncing";
import { ProductType } from "./ProductType";
import { useProductImages } from "./useProductImages";
import { useProductRatings } from "./useProductRatings";

interface IProductProps {
  title: string;
  files: Array<IStoreProductFile>;
  ratings: Array<IStoreProductRating>;
  currentPrice?: string;
  onProductClick: () => void;
}

export const Product: React.FC<IProductProps> = ({
  title,
  files,
  ratings,
  onProductClick,
  currentPrice,
}) => {
  const { primaryImage } = useProductImages();

  const { productRating } = useProductRatings(ratings);

  return (
    <div
      onClick={() => onProductClick()}
      className=" group rounded-lg pb-10  space-y-2 cursor-pointer flex flex-col min-w-[25rem] md:min-w-[15rem] xl:min-w-[20rem]"
    >
      <div className="  flex justify-center relative h-[15rem] overflow-hidden">
        <div className=" rounded-primary w-full flex justify-center items-center py-5 ">
          <div className=" flex items-end w-[15rem] bg-primary-fg-light rounded-3xl  ">
            <Image
              className=""
              radius="md"
              src={primaryImage(files)}
              alt={title}
            />
          </div>
        </div>

        {/* <PriceTagBouncing price={`${currentPrice}`} /> */}
      </div>
      <div className="px-sm space-y-2 text-primary-accent ">
        {" "}
        <div className="text-center  font-medium ">{title}</div>
        <div className="flex flex-col justify-center items-center">
          {" "}
          <div className="">
            {" "}
            {currentPrice && <PriceTag price={currentPrice} className="" />}
          </div>
        </div>
        <div className="flex justify-center">
          {" "}
          <RatingStars rating={productRating} />
        </div>
      </div>
    </div>
  );
};
