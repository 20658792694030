import React from "react";
import { CgSpinner } from "react-icons/cg";
interface ISpinnerProps {
  className?: string;
}

export const Spinner: React.FC<ISpinnerProps> = ({ className }) => {
  return (
    <CgSpinner className={`${className ? className : " "} animate-spin`} />
  );
};
