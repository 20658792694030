import React from "react";

interface IPriceTagProps {
  price: string;
  discountedPrice?: string;
  className?: string;
  bounce?: boolean;
}

export const PriceTag: React.FC<IPriceTagProps> = ({
  price,
  discountedPrice,
  className,
  bounce = false,
}) => {
  return (
    <div
      className={`${className ? className : ""}  rounded-sm ${
        bounce ? "group-hover:animate-bounce" : ""
      }  font-medium flex space-x-2`}
    >
      <div
        className={`${
          !!discountedPrice ? "line-through text-primary-light" : ""
        } `}
      >{`$ ${price}`}</div>
      {!!discountedPrice && (
        <div className="text-primary-red">{`$ ${discountedPrice}`}</div>
      )}
    </div>
  );
};
