import React from "react";

interface IProductGridProps {
  children?: React.ReactNode;
}

export const ProductsGrid: React.FC<IProductGridProps> = ({ children }) => {
  return (
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-10 py-2 ">
      {children}
    </div>
  );
};
