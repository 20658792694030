import { Image, TextInput, Kbd, Chips, Chip, ScrollArea } from "@mantine/core";
import { useBooleanToggle } from "@mantine/hooks";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiBox } from "react-icons/fi";
import { IoBookOutline } from "react-icons/io5";
import { MdChromeReaderMode, MdComputer } from "react-icons/md";
import { useQuery } from "react-query";
import { productsGet } from "../api/productsGet";
import { Product } from "../components/app/Product/Product";
import { ProductHorizontal } from "../components/app/Product/ProductHorizontal";
import { ProductsGrid } from "../components/app/Product/ProductsGrid";
import {
  BannerWithDiscount,
  BannerWithFreeShipping,
} from "../components/shared/Banner/BannerWithDiscount";
import { ModalPlain } from "../components/libs/Modal/ModalPlain";
import { NoResultFound } from "../components/shared/FallBacks/NoResultFound";
import { ZoniMaps } from "../components/shared/Maps/ZoniMaps";
import { NavBar } from "../components/shared/NavBar/NavBar";
import { ProductSearchBar } from "../components/shared/Search/ProductSearchBar";
import { APP_INFO } from "../constants/appInfo";
import { useProductTypes } from "../hooks/useProductTypes";
import { IStoreProductType } from "../models/product/types";

import { useNavigator } from "../pages-lib/navigation/useNavigator";
import { PageShell } from "../pages-lib/PageShell";
import { log } from "../utils/logger";
import { BannerLearnMore } from "../components/shared/Banner/BannerLearnMore";
import { Bannerv1 } from "../components/shared/Banner/Bannerv1";

const Home = () => {
  const router = useRouter();
  const activeBranchId = useMemo(() => {
    return 1;
  }, []);
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useQuery(
    ["products", { storeBranchId: activeBranchId }],
    () => productsGet({ storeBranchId: activeBranchId }),
    {
      enabled: !!activeBranchId,
    }
  );

  const { productTypes, productTypesIsLoading } = useProductTypes();

  const { navigateToProductDetail } = useNavigator();

  const [activeProductType, setActiveProductType] =
    useState<IStoreProductType>("BOOK");

  const filteredProductsByCateg = useMemo(() => {
    return products?.filter((p) => p.type === activeProductType);
  }, [products, activeProductType]);

  return (
    <PageShell NavigationBar={<NavBar></NavBar>}>
      {/* <BannerWithDiscount /> */}
      <BannerWithFreeShipping />
      <section className="section max-w-primary flex flex-col items-center space-y-6">
        <div className="flex space-x-5">
          {productTypes?.map((p) => (
            <button
              key={p.type}
              value={p.type}
              onClick={(n) => setActiveProductType(p.type)}
              className={`${
                p.type === activeProductType
                  ? "bg-primary-accent text-white"
                  : "text-primary-accent"
              } px-2 py-2 md:px-8 md:py-8 rounded-primary shadow-primary header-xs md:header-sm min-w-[10rem] flex  items-center justify-center space-x-4`}
            >
              <div className="text-2xl ">
                {" "}
                {p.type === "BOOK" ? (
                  <IoBookOutline />
                ) : p.type === "EBOOK" ? (
                  <MdComputer />
                ) : (
                  <FiBox />
                )}
              </div>
              <div>{p.name}</div>
            </button>
          ))}
        </div>
        {/* <div className="header-larger">Workbooks</div> */}

        <ProductsGrid>
          {" "}
          {filteredProductsByCateg && filteredProductsByCateg.length > 0 ? (
            filteredProductsByCateg.map((p) => (
              <Product
                onProductClick={() => navigateToProductDetail(p.productId)}
                key={p.storeProductId}
                currentPrice={p.currentPrice}
                files={p.files ?? []}
                ratings={p.ratings ?? []}
                title={p.title}
              />
            ))
          ) : (
            <div className="flex col-span-full">
              <NoResultFound
                caption={"There are no products under this category currently"}
              />
            </div>
          )}
        </ProductsGrid>
      </section>
      <BannerLearnMore />
    </PageShell>
  );
};

export default Home;
