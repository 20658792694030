import { Alert, Image } from "@mantine/core";
import React from "react";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { useUser, useUserBasedOnMS } from "../../../contexts/Msal/useMsalUser";
import { Button } from "../Button/Button";
import { PRICE_CONST } from "../../../constants/numbers";

export const BannerWithDiscount = () => {
  const msUser = useUserBasedOnMS();
  const { dbUser } = useUser();

  return (
    <div className="h-[35rem]  bg-primary-accent relative ">
      {/* <Image src={"/bannerDiscount.svg"} layout="fill" /> */}
      <div className="grid grid-cols-1  lg:grid-cols-3 h-full max-w-primary ">
        <div className=" relative flex items-center justify-center lg:justify-start lg:px-[5rem] ">
          <div className="flex flex-col  text-white z-20 relative">
            <div className="text-yellow-accent font-semibold text-2xl">
              SIGN IN TO GET
            </div>
            <div>
              {" "}
              <div className="text-7xl font-semibold">
                {PRICE_CONST.LOGIN_DISCOUNT_PERCENT} % OFF
              </div>
              <div className="text-4xl font-semibold"> ON TEXTBOOKS</div>
            </div>
            <div className="mt-6">
              <Button
                variant="red"
                className="text-lg  "
                onClick={() => msUser?.onLogin()}
              >
                GET YOUR DISCOUNT NOW
              </Button>
              {/* <div>{}</div> */}
              <div className="p-sm">
                {!!dbUser &&
                  (dbUser?.role === "student" ? (
                    <Alert
                      title=""
                      color="green"
                      classNames={{ root: "p-sm " }}
                      icon={<AiFillCheckCircle />}
                    >
                      <div className="text-xs text-primary-success">
                        Discount applies at checkout
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      title=""
                      color="yellow"
                      classNames={{ root: "p-sm" }}
                      icon={<AiFillWarning />}
                    >
                      <div className="text-xs text-primary-warning">
                        You must be logged in using student email.
                      </div>{" "}
                    </Alert>
                  ))}
              </div>
            </div>
            <div className="absolute -top-[5rem] -left-[5rem]">
              {" "}
              <Image alt="" src={"/lightnings.svg"} />
            </div>
            <div className="absolute -bottom-[5rem] -left-[2rem]">
              {" "}
              <Image alt="" src={"/bannerCircle.svg"} />
            </div>
          </div>
          <div className="absolute z-0 right-12 top-12">
            {" "}
            <Image alt="" src={"/bannerCircle.svg"} />
          </div>
          <div className="absolute z-0 right-12 bottom-12">
            {" "}
            <Image alt="" src={"/dots.svg"} />
          </div>
        </div>
        <div className="col-span-1 relative  hidden lg:flex lg:col-span-2 lg:justify-end overflow-hidden z-20">
          <Image alt="" src={"/bannerDiscount.png"} />
        </div>
        <div className="hidden lg:flex lg:absolute lg:right-0 w-1/4 2xl:w-1/2 h-full bg-[#FFDE01] z-0"></div>
      </div>
    </div>
  );
};

export const BannerWithFreeShipping = () => {
  const msUser = useUserBasedOnMS();
  const { dbUser } = useUser();

  return (
    <div className="h-[35rem]  bg-primary-accent relative ">
      {/* <Image src={"/bannerDiscount.svg"} layout="fill" /> */}
      <div className="grid grid-cols-1  lg:grid-cols-3 h-full max-w-primary ">
        <div className=" relative flex items-center justify-center lg:justify-start lg:px-[5rem] ">
          <div className="flex flex-col  text-white z-20 relative">
            <div className="text-yellow-accent font-semibold text-2xl">
              SIGN IN TO GET
            </div>
            <div>
              {" "}
              <div className="text-7xl font-semibold">FREE SHIPPING</div>
              {/* <div className="text-4xl font-semibold"> ON TEXTBOOKS</div> */}
            </div>
            <div className="mt-6">
              <Button
                variant="red"
                className="text-lg  "
                onClick={() => msUser?.onLogin()}
              >
                GET YOUR DISCOUNT NOW
              </Button>
              {/* <div>{}</div> */}
              <div className="p-sm">
                {dbUser?.role === "student" ? (
                  <Alert
                    title=""
                    color="green"
                    classNames={{ root: "p-sm " }}
                    icon={<AiFillCheckCircle />}
                  >
                    <div className="text-xs text-primary-success">
                      Discount applies at checkout. You must spend at least 32$
                      to be eligible for this deal.
                    </div>
                  </Alert>
                ) : (
                  <Alert
                    title=""
                    color="yellow"
                    classNames={{ root: "p-sm" }}
                    icon={<AiFillWarning />}
                  >
                    <div className="text-xs text-primary-warning">
                      You must spend at least 32$ to be eligible for this deal.
                    </div>{" "}
                  </Alert>
                )}
              </div>
            </div>
            <div className="absolute -top-[5rem] -left-[5rem]">
              {" "}
              <Image alt="" src={"/lightnings.svg"} />
            </div>
            <div className="absolute -bottom-[5rem] -left-[2rem]">
              {" "}
              <Image alt="" src={"/bannerCircle.svg"} />
            </div>
          </div>
          <div className="absolute z-0 right-12 top-12">
            {" "}
            <Image alt="" src={"/bannerCircle.svg"} />
          </div>
          <div className="absolute z-0 right-12 bottom-12">
            {" "}
            <Image alt="" src={"/dots.svg"} />
          </div>
        </div>
        <div className="col-span-1 relative  hidden lg:flex lg:col-span-2 lg:justify-end overflow-hidden z-20">
          <Image alt="" src={"/bannerDiscount.png"} />
        </div>
        <div className="hidden lg:flex lg:absolute lg:right-0 w-1/4 2xl:w-1/2 h-full bg-[#FFDE01] z-0"></div>
      </div>
    </div>
  );
};
