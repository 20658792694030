import React from "react";

interface IMarkdownProps {
  text: string;
  className?: string;
}

export const Markdown: React.FC<IMarkdownProps> = ({ text, className }) => {
  return (
    <div
      className={`${className ? className : ""} prose`}
      dangerouslySetInnerHTML={{
        __html: text?.replace?.(/&lt;/g, "<")?.replace(/&gt;/g, ">"),
      }}
    ></div>
  );
};
