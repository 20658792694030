import React from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Markdown } from "../../libs/Markdown/Markdown";

interface INoResultFoundProps {
  caption?: string;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  hideIcon?: boolean;
  className?: string;
}

export const NoResultFound: React.FC<INoResultFoundProps> = ({
  caption = "No result found",
  icon,
  action,
  hideIcon = false,
  className = " ",
}) => {
  return (
    <div
      className={`${className} flex justify-center h-full py-8 text-lightest `}
    >
      <div className="flex flex-col items-center space-y-4">
        {hideIcon ? null : !!icon ? (
          icon
        ) : (
          <AiOutlineFileSearch className="text-5xl " />
        )}

        <Markdown
          text={caption}
          className="text-center text-lighter"
        ></Markdown>
        {!!action ? action : null}
      </div>
    </div>
  );
};
