import { IStoreProductCateg, IStoreProductType } from "../models/product/types";
import { IProductsParams, IProductsResult } from "../pages/api/products";
import { fetcher } from "./fetcher";

export const productTypesGet = async () => {
  const res = await fetcher.fetch("/api/productTypes", {
    method: "POST",
    body: JSON.stringify({}),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const a = await res.json();
  if (!a.success) {
    throw new Error(JSON.stringify(a));
  } else {
    return a.data as Array<IStoreProductCateg>;
  }
};
