import React from "react";
import {
  Button as MButton,
  MantineNumberSize,
  MantineSize,
} from "@mantine/core";
import { Spinner } from "../../libs/Loader/Spinner";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant:
    | "primary"
    | "neutral"
    | "plain"
    | "danger"
    | "red"
    | "yellow"
    | "success";
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
  size?: MantineSize;
  radius?: MantineNumberSize;
  topRightIndicator?: React.ReactNode;
}

export const Button: React.FC<IButtonProps> = ({
  variant = "primary",
  className = "",
  leftIcon,
  children,
  loading = false,
  disabled,
  size,
  radius,
  topRightIndicator,
  ...props
}) => {
  return (
    <>
      <MButton
        {...props}
        leftIcon={loading ? null : leftIcon}
        radius={!!radius ? radius : "lg"}
        size={!!size ? size : "md"}
        classNames={{
          root: ` ${className} relative ${
            variant === "primary"
              ? "button-primary"
              : variant === "neutral"
              ? "button-neutral"
              : variant === "danger"
              ? "button-danger"
              : variant === "red"
              ? "button-red"
              : variant === "success"
              ? "button-success"
              : variant === "yellow"
              ? "button-yellow"
              : "button-plain "
          }`,
        }}
        disabled={loading || disabled}
      >
        {loading ? <Spinner /> : children}
        {topRightIndicator && (
          <div className="absolute -top-1 -right-1">{topRightIndicator}</div>
        )}
      </MButton>
    </>
  );
};
