import React from "react";
import { useQuery } from "react-query";
import { productTypesGet } from "../api/productTypesGet";

export const useProductTypes = () => {
  const { data: productTypes, isLoading: productTypesIsLoading } = useQuery(
    ["productTypes"],
    productTypesGet,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return { productTypes, productTypesIsLoading };
};
