export const PRODUCTS_CONST = {
  MAX_AVAILABLE: 1000,
  MAX_NUM_OF_SECONDARY_PICTURE: 7,
};

export const SHIPPING_PACKAGE_CONST = {
  HEIGHT: 9, //inches
  WIDTH: 1,
  LENGTH: 7,
  MIN_WEIGHT: 1.25,
  ORIGIN_ZIP: "07093",
};

export const ORDERS_CONST = {
  ADMIN_ORDERS_PER_PAGE: 10,
};

export const POUNDS_TO_OUNCES = 16;

export const PRICE_CONST = {
  LOGIN_DISCOUNT_PERCENT: 5,
};

export const RETURN_CONST = {
  MAX_DAYS_TO_RETURN_A_ORDER: 30,
};
