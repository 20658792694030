import { MdOutlineOpenInNew } from "react-icons/md";
import { ZONI_LIVE_PAGES } from "../../../constants/links";
import { openNewTab } from "../../../utils/openNewTab";
import { Button } from "../Button/Button";

export const BannerLearnMore = () => {
  return (
    <div className="bg-gray-50">
      <div
        className="h-[35rem]   relative bg-no-repeat bg-center text-white		 shadow-primary"
        style={{ backgroundImage: `url(${"/threePeople.png"})` }}
      >
        <div className="flex flex-col items-center justify-end  h-full space-y-6 py-8">
          {" "}
          <div className="header-larger text-white text-center">
            Say
            <span className="text-primary-yellow">{` hello `}</span>
            to your new life
            <span className="text-primary-yellow">{` learning English `}</span>
            with
            <span className="text-primary-yellow">{` Zoni `}</span>!
          </div>
          <Button
            variant="yellow"
            size="xl"
            onClick={() => openNewTab(ZONI_LIVE_PAGES.CONTACT_US)}
            className={""}
          >
            Contact Us
          </Button>
        </div>

        {/* <Image src={"/bannerDiscount.svg"} layout="fill" /> */}
      </div>
    </div>
  );
};
